import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'pagina',
    component: () => import('../views/PaginaView.vue')
  }, {
    path: '/transaccionrv',
    name: 'transaccionrv',
    mode: 'abstract',
    component: () => import('../views/TransaccionRVView.vue')
  }, {
    path: '/transaccioncu',
    name: 'transaccioncu',
    mode: 'abstract',
    component: () => import('../views/TransaccionCUView.vue')
  }, {
    path: '/transaccionce',
    name: 'transaccionc',
    mode: 'abstract',
    component: () => import('../views/TransaccionCEView.vue')
  }, {
    path: '/cursos',
    name: 'cursos',
    mode: 'abstract',
    component: () => import('../views/CursosView.vue')
  }, {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/ErrorNotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
