import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import axios from 'axios'
import VueAxios from 'vue-axios'
import quasarUserOptions from './quasar-user-options'
import VueApexCharts from 'vue3-apexcharts'

createApp(App).use(Quasar, quasarUserOptions).use(router).use(VueAxios, axios).use(createPinia()).use(VueApexCharts).mount('#app')
